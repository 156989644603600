import React, { Component } from 'react'
import SeoHead from 'Components/SeoHead'
import Layout from 'Components/Layout'
import AppActions from 'AppActions'
import AppConstants from 'AppConstants'
import Breadcrumbs from 'Components/UI/Breadcrumbs'
import Video from 'Components/UI/Video'
import Img from 'Components/UI/Img'
import { navigate } from 'gatsby'
import LazyLoad from 'react-lazyload'
import styles from './index.module.scss'

const data = [
  {
    title: `Climb A Balloon`,
    instructions: `<span>01.</span> Search for the absolutely biggest balloon you can possibly find <span>02.</span> Each player attempts to climb up the balloon <span>03.</span> The goal is to reach the top of the balloon before the other players`,
    background: {
      src: 'https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/balloon.mp4',
      srcMobile: 'https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/balloon.mp4'
    },
    products: [
      {
        permalink: 'ss19/crystal-xxl-chain/crystal-xxl-chain-85-sandal',
        title: 'Crystal Xxl Chain 85 Sandal',
        color: 'black',
        thumb: {
          src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/balloon/products/SS19SL629-STELLA_LUNA-SANDAL-1_300.png`,
          srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/balloon/products/SS19SL629-STELLA_LUNA-SANDAL-1_500.png`
        }
      },
      {
        permalink: 'ss19/crystal-xxl-chain/crystal-xxl-chain-105-sandal-2',
        title: 'Crystal Xxl Chain 105 Sandal',
        color: 'silver',
        thumb: {
          src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/balloon/products/SS19SL633-STELLA_LUNA-SANDAL-1_300.png`,
          srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/balloon/products/SS19SL633-STELLA_LUNA-SANDAL-1_500.png`
        }
      },
      {
        permalink: 'ss19/crystal-xxl-chain/crystal-xxl-chain-105-sandal',
        title: 'Crystal Xxl Chain 105 Sandal',
        color: 'black',
        thumb: {
          src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/balloon/products/SS19SL632-STELLA_LUNA-SANDAL-1_300.png`,
          srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/balloon/products/SS19SL632-STELLA_LUNA-SANDAL-1_500.png`
        }
      }
    ],
    content: [
      {
        className: `u-offset-2 u-col-8 u-offset-lg-1 u-col-lg-4`,
        src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/balloon/editorial/normal/07_STELLA_LUNA_190220_264.jpg`,
        srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/balloon/editorial/retina/07_STELLA_LUNA_190220_264.jpg`
      },
      {
        className: `u-offset-0 u-col-9 u-col-lg-4 u-offset-lg-2 u-mrg-t--300 u-mrg-reset-lg`,
        src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/balloon/editorial/normal/07_STELLA_LUNA_190220_471.jpg`,
        srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/balloon/editorial/retina/07_STELLA_LUNA_190220_471.jpg`
      },
      {
        className: `u-offset-3 u-col-9 u-col-lg-4 u-offset-lg-2`,
        src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/balloon/editorial/normal/07_STELLA_LUNA_190220_743.jpg`,
        srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/balloon/editorial/retina/07_STELLA_LUNA_190220_743.jpg`
      },
      {
        className: `u-offset-2 u-col-10 u-offset-lg-3 u-col-lg-3 u-mrg-t--200 u-mrg-reset-lg`,
        src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/balloon/editorial/normal/07_STELLA_LUNA_190220_823.jpg`,
        srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/balloon/editorial/retina/07_STELLA_LUNA_190220_823.jpg`
      },
      {
        className: `u-offset-1 u-col-7 u-col-lg-4 u-offset-lg-0 u-mrg-t--300 u-mrg-reset-lg`,
        src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/balloon/editorial/normal/07_STELLA_LUNA_190220_953.jpg`,
        srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/balloon/editorial/retina/07_STELLA_LUNA_190220_953.jpg`
      },
      {
        className: `u-offset-2 u-col-9 u-col-lg-4 u-offset-lg-1 u-mrg-t--100 u-mrg-reset-lg`,
        src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/balloon/editorial/normal/07_STELLA_LUNA_190220_1185.jpg`,
        srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/balloon/editorial/retina/07_STELLA_LUNA_190220_1185.jpg`
      }
    ]
  },
  // {
  //   title: `Tie In A Knot`,
  //   instructions: `<span>01.</span> X and Y co-ordinates are mapped on the ground <span>02.</span> A referee calls out moves, directing players to place feet or hands on specific locations <span>03.</span> As players arrange themselves into more and more difficult positions, eventually someone will fall and be eliminated <span>04.</span> Last person to not fall wins`,
  //   background: {
  //     src: 'https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/twister_1.mp4',
  //     srcMobile: 'https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/twister_1.mp4'
  //   },
  //   products: [
  //     {
  //       permalink: 'ss19/plisse/plisse-flat-2',
  //       title: 'Plissé Flat',
  //       color: 'gold',
  //       thumb: {
  //         src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/tie_in_a_knot/products/SS19SL606-STELLA_LUNA-SANDAL-1_300.png`,
  //         srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/tie_in_a_knot/products/SS19SL606-STELLA_LUNA-SANDAL-1_500.png`
  //       }
  //     },
  //     {
  //       permalink: 'ss19/plisse/plisse-flat',
  //       title: 'Plissé Flat',
  //       color: 'black',
  //       thumb: {
  //         src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/tie_in_a_knot/products/SS19SL605-STELLA_LUNA-SANDAL-1_300.png`,
  //         srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/tie_in_a_knot/products/SS19SL605-STELLA_LUNA-SANDAL-1_500.png`
  //       }
  //     }
  //   ],
  //   content: [
  //     {
  //       className: `u-offset-0 u-col-9 u-offset-lg-1 u-col-lg-4`,
  //       src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/tie_in_a_knot/editorial/normal/13_STELLA_LUNA_190221_074.jpg`,
  //       srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/tie_in_a_knot/editorial/retina/13_STELLA_LUNA_190221_074.jpg`
  //     },
  //     {
  //       className: `u-offset-4 u-col-8 u-col-lg-3 u-offset-lg-3 u-mrg-t--200 u-mrg-reset-lg`,
  //       src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/tie_in_a_knot/editorial/normal/13_STELLA_LUNA_190221_237.jpg`,
  //       srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/tie_in_a_knot/editorial/retina/13_STELLA_LUNA_190221_237.jpg`
  //     },
  //     {
  //       className: `u-offset-1 u-col-10 u-col-lg-4 u-offset-lg-3 u-mrg-t--100 u-mrg-reset-lg`,
  //       src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/tie_in_a_knot/editorial/normal/13_STELLA_LUNA_190221_286.jpg`,
  //       srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/tie_in_a_knot/editorial/retina/13_STELLA_LUNA_190221_286.jpg`
  //     }
  //   ]
  // },
  {
    title: `Twist Around`,
    instructions: `<span>01.</span> Game players stand, facing each other, hand-in-hand <span>02.</span> As music starts, they begin to move to reposition themselves, back-to-back <span>03.</span> They must always hold the other players hands`,
    background: {
      src: 'https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/tangled.mp4',
      srcMobile: 'https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/tangled.mp4'
    },
    products: [
      {
        permalink: 'ss19/velvet-bow/velvet-bow-mule',
        title: 'Velvet Bow Mule',
        color: 'black',
        thumb: {
          src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/twist_around/products/SS19SL208-STELLA_LUNA-SLIPPER-1_300.png`,
          srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/twist_around/products/SS19SL208-STELLA_LUNA-SLIPPER-1_500.png`
        }
      },
      {
        permalink: 'ss19/velvet-bow/velvet-bow-mule-2',
        title: 'Velvet Bow Mule',
        color: 'pink',
        thumb: {
          src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/twist_around/products/SS19SL209-STELLA_LUNA-SLIPPER-1_300.png`,
          srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/twist_around/products/SS19SL209-STELLA_LUNA-SLIPPER-1_500.png`
        }
      },
      {
        permalink: 'ss19/velvet-bow/velvet-bow-sandal-2',
        title: 'Velvet Bow Sandal',
        color: 'pink',
        thumb: {
          src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/twist_around/products/SS19SL233-STELLA_LUNA-SANDAL-1_300.png`,
          srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/twist_around/products/SS19SL233-STELLA_LUNA-SANDAL-1_500.png`
        }
      }
    ],
    content: [
      {
        className: `u-offset-1 u-col-10 u-offset-lg-1 u-col-lg-4`,
        src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/twist_around/editorial/normal/14_STELLA_LUNA_190221_170.jpg`,
        srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/twist_around/editorial/retina/14_STELLA_LUNA_190221_170.jpg`
      },
      {
        className: `u-offset-0 u-col-9 u-col-lg-3 u-offset-lg-3 u-mrg-t--200 u-mrg-reset-lg`,
        src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/twist_around/editorial/normal/14_STELLA_LUNA_190221_264.jpg`,
        srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/twist_around/editorial/retina/14_STELLA_LUNA_190221_264.jpg`
      },
      {
        className: `u-offset-2 u-col-9 u-col-lg-4 u-offset-lg-3 u-mrg-t--100 u-mrg-reset-lg`,
        src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/twist_around/editorial/normal/14_STELLA_LUNA_190221_391.jpg`,
        srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/twist_around/editorial/retina/14_STELLA_LUNA_190221_391.jpg`
      },
      {
        className: `u-offset-0 u-col-7 u-col-lg-4 u-offset-lg-4 u-mrg-t--200 u-mrg-reset-lg`,
        src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/twist_around/editorial/normal/14_STELLA_LUNA_190221_444.jpg`,
        srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/twist_around/editorial/retina/14_STELLA_LUNA_190221_444.jpg`
      },
      {
        className: `u-offset-3 u-col-9 u-offset-lg-2 u-col-lg-4 u-mrg-t--100 u-mrg-reset-lg`,
        src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/twist_around/editorial/normal/14_STELLA_LUNA_190221_483.jpg`,
        srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/twist_around/editorial/retina/14_STELLA_LUNA_190221_483.jpg`
      }
    ]
  },
  // {
  //   title: `Simon Says`,
  //   instructions: `<span>01.</span> Simon (the designated leader) tells other members of the group what they must do <span>02.</span> Players need to be careful to only obey commands that begin with the phrase “Simon says…” <span>03.</span> If she obeys a command that does not begin with “Simon says,” the player is eliminated`,
  //   background: {
  //     src: 'https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/simon_says_2.mp4',
  //     srcMobile: 'https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/simon_says_2.mp4'
  //   },
  //   products: [
  //     {
  //       permalink: 'ss19/double-ring/double-ring-slider-4',
  //       title: 'Double Ring Slider',
  //       color: 'black',
  //       thumb: {
  //         src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/simon_says_2/products/SS19SL477-STELLA_LUNA-SLIPPER-1_300.png`,
  //         srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/simon_says_2/products/SS19SL477-STELLA_LUNA-SLIPPER-1_500.png`
  //       }
  //     },
  //     {
  //       permalink: 'ss19/double-ring/double-ring-slider-3',
  //       title: 'Double Ring Slider',
  //       color: 'fuchsia',
  //       thumb: {
  //         src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/simon_says_2/products/SS19SL475-STELLA_LUNA-SLIPPER-1_300.png`,
  //         srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/simon_says_2/products/SS19SL475-STELLA_LUNA-SLIPPER-1_500.png`
  //       }
  //     },
  //     {
  //       permalink: 'ss19/double-ring/double-ring-slider',
  //       title: 'Double Ring Slider',
  //       color: 'olive',
  //       thumb: {
  //         src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/simon_says_2/products/SS19SL473-STELLA_LUNA-SLIPPER-1_300.png`,
  //         srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/simon_says_2/products/SS19SL473-STELLA_LUNA-SLIPPER-1_500.png`
  //       }
  //     }
  //   ],
  //   content: [
  //     {
  //       className: `u-offset-3 u-col-9 u-offset-lg-1 u-col-lg-4`,
  //       src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/simon_says_2/editorial/normal/10_STELLA_LUNA_190221_053.jpg`,
  //       srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/simon_says_2/editorial/retina/10_STELLA_LUNA_190221_053.jpg`
  //     },
  //     {
  //       className: `u-offset-1 u-col-6 u-col-lg-3 u-offset-lg-3 u-mrg-t--200 u-mrg-reset-lg`,
  //       src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/simon_says_2/editorial/normal/10_STELLA_LUNA_190221_081.jpg`,
  //       srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/simon_says_2/editorial/retina/10_STELLA_LUNA_190221_081.jpg`
  //     },
  //     {
  //       className: `u-offset-0 u-col-11 u-col-lg-4 u-offset-lg-3 u-mrg-t--100 u-mrg-reset-lg`,
  //       src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/simon_says_2/editorial/normal/10_STELLA_LUNA_190221_113.jpg`,
  //       srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/simon_says_2/editorial/retina/10_STELLA_LUNA_190221_113.jpg`
  //     },
  //     {
  //       className: `u-offset-3 u-col-7 u-col-lg-4 u-offset-lg-4 u-mrg-t--200 u-mrg-reset-lg`,
  //       src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/simon_says_2/editorial/normal/10_STELLA_LUNA_190221_164.jpg`,
  //       srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/simon_says_2/editorial/retina/10_STELLA_LUNA_190221_164.jpg`
  //     },
  //     {
  //       className: `u-offset-1 u-col-8 u-offset-lg-2 u-col-lg-4 u-mrg-t--100 u-mrg-reset-lg`,
  //       src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/simon_says_2/editorial/normal/10_STELLA_LUNA_190221_204.jpg`,
  //       srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/simon_says_2/editorial/retina/10_STELLA_LUNA_190221_204.jpg`
  //     }
  //   ]
  // },
  {
    title: `Simon Says`,
    instructions: `<span>01.</span> Simon (the designated leader) tells other members of the group what they must do <span>02.</span> Players need to be careful to only obey commands that begin with the phrase “Simon says…” <span>03.</span> If she obeys a command that does not begin with “Simon says,” the player is eliminated`,
    background: {
      src: 'https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/simon_says_1.mp4',
      srcMobile: 'https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/simon_says_1.mp4'
    },
    products: [
      {
        permalink: 'ss19/plisse/plisse-sandal',
        title: 'Plissé Sandal',
        color: 'black',
        thumb: {
          src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/simon_says_1/products/SS19SL608-STELLA_LUNA-SANDAL-1_300.png`,
          srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/simon_says_1/products/SS19SL608-STELLA_LUNA-SANDAL-1_500.png`
        }
      },
      {
        permalink: 'ss19/plisse/plisse-flat-2',
        title: 'Plissé Flat',
        color: 'gold',
        thumb: {
          src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/simon_says_1/products/SS19SL606-STELLA_LUNA-SANDAL-1_300.png`,
          srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/simon_says_1/products/SS19SL606-STELLA_LUNA-SANDAL-1_500.png`
        }
      },
      {
        permalink: 'ss19/plisse/plisse-flat',
        title: 'Plissé Flat',
        color: 'black',
        thumb: {
          src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/simon_says_1/products/SS19SL605-STELLA_LUNA-SANDAL-1_300.png`,
          srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/simon_says_1/products/SS19SL605-STELLA_LUNA-SANDAL-1_500.png`
        }
      }
    ],
    content: [
      {
        className: `u-offset-0 u-col-9 u-offset-lg-1 u-col-lg-4`,
        src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/simon_says_1/editorial/normal/03_STELLA_LUNA_190220_153.jpg`,
        srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/simon_says_1/editorial/retina/03_STELLA_LUNA_190220_153.jpg`
      },
      {
        className: `u-offset-3 u-col-9 u-col-lg-3 u-offset-lg-3 u-mrg-t--200 u-mrg-reset-lg`,
        src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/simon_says_1/editorial/normal/03_STELLA_LUNA_190220_631.jpg`,
        srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/simon_says_1/editorial/retina/03_STELLA_LUNA_190220_631.jpg`
      },
      {
        className: `u-offset-2 u-col-7 u-col-lg-4 u-offset-lg-3 u-mrg-t--100 u-mrg-reset-lg`,
        src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/simon_says_1/editorial/normal/03_STELLA_LUNA_190220_642.jpg`,
        srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/simon_says_1/editorial/retina/03_STELLA_LUNA_190220_642.jpg`
      },
      {
        className: `u-offset-3 u-col-8 u-col-lg-4 u-offset-lg-4 u-mrg-t--200 u-mrg-reset-lg`,
        src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/simon_says_1/editorial/normal/03_STELLA_LUNA_190220_679.jpg`,
        srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/simon_says_1/editorial/retina/03_STELLA_LUNA_190220_679.jpg`
      },
      {
        className: `u-offset-0 u-col-9 u-offset-lg-2 u-col-lg-4 u-mrg-t--100 u-mrg-reset-lg`,
        src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/simon_says_1/editorial/normal/03_STELLA_LUNA_190220_699.jpg`,
        srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/simon_says_1/editorial/retina/03_STELLA_LUNA_190220_699.jpg`
      }
    ]
  },
  // {
  //   title: `A Big Pile of Shoes`,
  //   instructions: `<span>01.</span> Players remove their shoes and place in a pile <span>02.</span> Other shoes are added to the pile—a mess is created <span>03.</span> Players go sit, far away from the pile <span>04.</span> On the count of three, they rush to the pile, find their shoes <span>05.</span> The first to put on her shoes wins the game`,
  //   background: {
  //     src: 'https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/pile_of_shoes.mp4',
  //     srcMobile: 'https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/pile_of_shoes.mp4'
  //   },
  //   products: [
  //     {
  //       permalink: 'ss19/stella-buckle/stella-slider-2',
  //       title: 'Stella Slider',
  //       color: 'orange',
  //       thumb: {
  //         src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/pile_of_shoes/products/SS19SL461-STELLA_LUNA-SLIPPER-1_300.png`,
  //         srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/pile_of_shoes/products/SS19SL461-STELLA_LUNA-SLIPPER-1_500.png`
  //       }
  //     },
  //     {
  //       permalink: 'ss19/stella-buckle/stella-slider',
  //       title: 'Stella Slider',
  //       color: 'fuchsia',
  //       thumb: {
  //         src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/pile_of_shoes/products/SS19SL460-STELLA_LUNA-SLIPPER-1_300.png`,
  //         srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/pile_of_shoes/products/SS19SL460-STELLA_LUNA-SLIPPER-1_500.png`
  //       }
  //     }
  //   ],
  //   content: [
  //     {
  //       className: `u-offset-2 u-col-9 u-offset-lg-1 u-col-lg-4`,
  //       src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/pile_of_shoes/editorial/normal/11_STELLA_LUNA_190221_216.jpg`,
  //       srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/pile_of_shoes/editorial/retina/11_STELLA_LUNA_190221_216.jpg`
  //     },
  //     {
  //       className: `u-offset-1 u-col-6 u-col-lg-3 u-offset-lg-3 u-mrg-t--200 u-mrg-reset-lg`,
  //       src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/pile_of_shoes/editorial/normal/11_STELLA_LUNA_190221_096.jpg`,
  //       srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/pile_of_shoes/editorial/retina/11_STELLA_LUNA_190221_096.jpg`
  //     },
  //     {
  //       className: `u-offset-4 u-col-8 u-col-lg-4 u-offset-lg-3 u-mrg-t--100 u-mrg-reset-lg`,
  //       src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/pile_of_shoes/editorial/normal/11_STELLA_LUNA_190221_210.jpg`,
  //       srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/pile_of_shoes/editorial/retina/11_STELLA_LUNA_190221_210.jpg`
  //     }
  //   ]
  // },
  {
    title: `Tie In A Knot`,
    instructions: `<span>01.</span> X and Y co-ordinates are mapped on the ground <span>02.</span> A referee calls out moves, directing players to place feet or hands on specific locations <span>03.</span> As players arrange themselves into more and more difficult positions, eventually someone will fall and be eliminated <span>04.</span> Last person to not fall wins`,
    background: {
      src: 'https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/twister_2.mp4',
      srcMobile: 'https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/twister_2.mp4'
    },
    products: [
      {
        permalink: 'ss19/stella/stella-mule-3',
        title: 'Stella Mule',
        color: 'black',
        thumb: {
          src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/tie_in_a_knot_2/products/SS19SL116-STELLA_LUNA-MULE&SLIDE-1_300.png`,
          srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/tie_in_a_knot_2/products/SS19SL116-STELLA_LUNA-MULE&SLIDE-1_500.png`
        }
      },
      {
        permalink: 'ss19/stella/stella-mule',
        title: 'Stella Mule',
        color: 'fuchsia',
        thumb: {
          src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/tie_in_a_knot_2/products/SS19SL114-STELLA_LUNA-MULE&SLIDE-1_300.png`,
          srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/tie_in_a_knot_2/products/SS19SL114-STELLA_LUNA-MULE&SLIDE-1_500.png`
        }
      },
      {
        permalink: 'ss19/stella/stella-mule-2',
        title: 'Stella Mule',
        color: 'champagne',
        thumb: {
          src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/tie_in_a_knot_2/products/SS19SL115-STELLA_LUNA-MULE&SLIDE-1_300.png`,
          srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/tie_in_a_knot_2/products/SS19SL115-STELLA_LUNA-MULE&SLIDE-1_500.png`
        }
      }
    ],
    content: [
      {
        className: `u-offset-1 u-col-10 u-offset-lg-1 u-col-lg-4`,
        src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/tie_in_a_knot_2/editorial/normal/06_STELLA_LUNA_190220_024.jpg`,
        srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/tie_in_a_knot_2/editorial/retina/06_STELLA_LUNA_190220_024.jpg`
      },
      {
        className: `u-offset-0 u-col-9 u-col-lg-4 u-offset-lg-2 u-mrg-t--300 u-mrg-reset-lg`,
        src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/tie_in_a_knot_2/editorial/normal/06_STELLA_LUNA_190220_171.jpg`,
        srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/tie_in_a_knot_2/editorial/retina/06_STELLA_LUNA_190220_171.jpg`
      },
      {
        className: `u-offset-2 u-col-8 u-col-lg-4 u-offset-lg-2`,
        src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/tie_in_a_knot_2/editorial/normal/06_STELLA_LUNA_190220_198.jpg`,
        srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/tie_in_a_knot_2/editorial/retina/06_STELLA_LUNA_190220_198.jpg`
      },
      {
        className: `u-offset-1 u-col-7 u-offset-lg-3 u-col-lg-3 u-mrg-t--100 u-mrg-reset-lg`,
        src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/tie_in_a_knot_2/editorial/normal/06_STELLA_LUNA_190220_203.jpg`,
        srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/tie_in_a_knot_2/editorial/retina/06_STELLA_LUNA_190220_203.jpg`
      },
      {
        className: `u-offset-4 u-col-8 u-col-lg-4 u-offset-lg-1 u-mrg-t--100 u-mrg-reset-lg`,
        src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/tie_in_a_knot_2/editorial/normal/06_STELLA_LUNA_190220_264.jpg`,
        srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/tie_in_a_knot_2/editorial/retina/06_STELLA_LUNA_190220_264.jpg`
      },
      {
        className: `u-offset-2 u-col-9 u-col-lg-3 u-offset-lg-2 u-mrg-t--200 u-mrg-reset-lg`,
        src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/tie_in_a_knot_2/editorial/normal/06_STELLA_LUNA_190220_316.jpg`,
        srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/tie_in_a_knot_2/editorial/retina/06_STELLA_LUNA_190220_316.jpg`
      },
    ]
  },
  {
    title: `Palm Push`,
    instructions: `<span>01.</span> Players stand, face-to-face <span>02.</span> Each player pairs her palms with the opposing player’s palm <span>03.</span> Each of the two players attempts to push the opposing player off balance, forcing her to move her feet`,
    background: {
      src: 'https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/palm_push.mp4',
      srcMobile: 'https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/palm_push.mp4'
    },
    products: [
      {
        permalink: 'ss19/crystal-xxl-chain/crystal-xxl-chain-85-sandal',
        title: 'Crystal Xxl Chain 85 Sandal',
        color: 'black',
        thumb: {
          src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/balloon/products/SS19SL629-STELLA_LUNA-SANDAL-1_300.png`,
          srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/balloon/products/SS19SL629-STELLA_LUNA-SANDAL-1_500.png`
        }
      },
      {
        permalink: 'ss19/crystal-xxl-chain/crystal-xxl-chain-105-sandal-2',
        title: 'Crystal Xxl Chain 105 Sandal',
        color: 'silver',
        thumb: {
          src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/balloon/products/SS19SL633-STELLA_LUNA-SANDAL-1_300.png`,
          srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/balloon/products/SS19SL633-STELLA_LUNA-SANDAL-1_500.png`
        }
      },
      {
        permalink: 'ss19/crystal-xxl-chain/crystal-xxl-chain-105-sandal',
        title: 'Crystal Xxl Chain 105 Sandal',
        color: 'black',
        thumb: {
          src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/balloon/products/SS19SL632-STELLA_LUNA-SANDAL-1_300.png`,
          srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/balloon/products/SS19SL632-STELLA_LUNA-SANDAL-1_500.png`
        }
      }
    ],
    content: [
      {
        className: `u-offset-0 u-col-9 u-offset-lg-1 u-col-lg-4`,
        src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/palm_push/editorial/normal/04_STELLA_LUNA_190220_029.jpg`,
        srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/palm_push/editorial/retina/04_STELLA_LUNA_190220_029.jpg`
      },
      {
        className: `u-offset-2 u-col-9 u-col-lg-4 u-offset-lg-2 u-mrg-t--300 u-mrg-reset-lg`,
        src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/palm_push/editorial/normal/04_STELLA_LUNA_190220_143.jpg`,
        srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/palm_push/editorial/retina/04_STELLA_LUNA_190220_143.jpg`
      },
      {
        className: `u-offset-1 u-col-6 u-col-lg-4 u-offset-lg-2`,
        src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/palm_push/editorial/normal/04_STELLA_LUNA_190220_157.jpg`,
        srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/palm_push/editorial/retina/04_STELLA_LUNA_190220_157.jpg`
      },
      {
        className: `u-offset-4 u-col-8 u-offset-lg-2 u-col-lg-3 u-mrg-t--100 u-mrg-reset-lg`,
        src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/palm_push/editorial/normal/04_STELLA_LUNA_190220_302.jpg`,
        srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/palm_push/editorial/retina/04_STELLA_LUNA_190220_302.jpg`
      },
      {
        className: `u-offset-2 u-col-8 u-col-lg-4 u-offset-lg-0 u-mrg-t--100 u-mrg-reset-lg`,
        src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/palm_push/editorial/normal/04_STELLA_LUNA_190220_314.jpg`,
        srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/palm_push/editorial/retina/04_STELLA_LUNA_190220_314.jpg`
      },
      {
        className: `u-offset-0 u-col-9 u-col-lg-4 u-offset-lg-4 u-mrg-t--200 u-mrg-reset-lg`,
        src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/palm_push/editorial/normal/04_STELLA_LUNA_190220_350.jpg`,
        srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/palm_push/editorial/retina/04_STELLA_LUNA_190220_350.jpg`
      },
      {
        className: `u-offset-2 u-col-9 u-offset-lg-2 u-col-lg-4 u-mrg-t--100 u-mrg-reset-lg`,
        src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/palm_push/editorial/normal/04_STELLA_LUNA_190220_353.jpg`,
        srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/palm_push/editorial/retina/04_STELLA_LUNA_190220_353.jpg`
      },
      {
        className: `u-offset-1 u-col-5 u-col-lg-4 u-offset-lg-2 u-mrg-t--200 u-mrg-reset-lg`,
        src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/palm_push/editorial/normal/04_STELLA_LUNA_190220_465.jpg`,
        srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/palm_push/editorial/retina/04_STELLA_LUNA_190220_465.jpg`
      },
      {
        className: `u-offset-2 u-col-9 u-col-lg-3 u-offset-lg-4 u-mrg-t--100 u-mrg-reset-lg`,
        src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/palm_push/editorial/normal/04_STELLA_LUNA_190220_545.jpg`,
        srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/palm_push/editorial/retina/04_STELLA_LUNA_190220_545.jpg`
      }
    ]
  },
  {
    title: `Back to Back`,
    instructions: `<span>01.</span> Players sit, back-to-back, with elbows linked  <span>02.</span> Relying only on her own strength and the support of her partner, each player attempts to stand up`,
    background: {
      src: 'https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/back_to_back.mp4',
      srcMobile: 'https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/back_to_back.mp4'
    },
    products: [
      {
        permalink: 'ss19/lace-bow/lace-bow-sandal',
        title: 'Lace Bow Sandal',
        color: 'black',
        thumb: {
          src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/back_to_back/products/SS19SL277-STELLA_LUNA-SANDAL-1_300.png`,
          srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/back_to_back/products/SS19SL277-STELLA_LUNA-SANDAL-1_500.png`
        }
      },
      {
        permalink: 'ss19/lace-bow/lace-bow-pump',
        title: 'Lace Bow Pump',
        color: 'black',
        thumb: {
          src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/back_to_back/products/SS19SL276-STELLA_LUNA-PUMP-1_300.png`,
          srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/back_to_back/products/SS19SL276-STELLA_LUNA-PUMP-1_500.png`
        }
      }
    ],
    content: [
      {
        className: `u-offset-2 u-col-7 u-offset-lg-1 u-col-lg-4`,
        src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/back_to_back/editorial/normal/09_STELLA_LUNA_190221_060.jpg`,
        srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/back_to_back/editorial/retina/09_STELLA_LUNA_190221_060.jpg`
      },
      {
        className: `u-offset-6 u-col-6 u-col-lg-4 u-offset-lg-2 u-mrg-t--300 u-mrg-reset-lg`,
        src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/back_to_back/editorial/normal/09_STELLA_LUNA_190221_117.jpg`,
        srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/back_to_back/editorial/retina/09_STELLA_LUNA_190221_117.jpg`
      },
      {
        className: `u-offset-2 u-col-9 u-col-lg-4 u-offset-lg-2`,
        src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/back_to_back/editorial/normal/09_STELLA_LUNA_190221_184.jpg`,
        srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/back_to_back/editorial/retina/09_STELLA_LUNA_190221_184.jpg`
      },
      {
        className: `u-offset-1 u-col-9 u-offset-lg-3 u-col-lg-3 u-mrg-t--200 u-mrg-reset-lg`,
        src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/back_to_back/editorial/normal/09_STELLA_LUNA_190221_254.jpg`,
        srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/back_to_back/editorial/retina/09_STELLA_LUNA_190221_254.jpg`
      },
      {
        className: `u-offset-4 u-col-7 u-col-lg-4 u-offset-lg-0 u-mrg-t--300 u-mrg-reset-lg`,
        src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/back_to_back/editorial/normal/09_STELLA_LUNA_190221_263.jpg`,
        srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/back_to_back/editorial/retina/09_STELLA_LUNA_190221_263.jpg`
      },
      {
        className: `u-offset-0 u-col-9 u-col-lg-4 u-offset-lg-1 u-mrg-t--100 u-mrg-reset-lg`,
        src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/back_to_back/editorial/normal/09_STELLA_LUNA_190221_295.jpg`,
        srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/back_to_back/editorial/retina/09_STELLA_LUNA_190221_295.jpg`
      }
    ]
  },
  {
    title: `In & Out`,
    instructions: `<span>01.</span> Set out a square on the ground  <span>02.</span> The designated game leader shouts out the commands “go in” and “go out”  <span>03.</span> The game leader aims to repeat the instructions in a rapid and confusing manner  <span>04.</span> The player who does not follow the instruction is eliminated`,
    background: {
      src: 'https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/in_out_video_3.mp4',
      srcMobile: 'https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/in_out_video_3.mp4'
    },
    products: [
      {
        permalink: 'ss19/velvet-bow/velvet-bow-sandal-2',
        title: 'Velvet Bow Sandal',
        color: 'pink',
        thumb: {
          src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/in_and_out/products/SS19SL233-STELLA_LUNA-SANDAL-1_300x300.png`,
          srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/in_and_out/products/SS19SL233-STELLA_LUNA-SANDAL-1_500x500.png`
        }
      },
      {
        permalink: 'ss19/velvet-bow/velvet-bow-sandal',
        title: 'Velvet Bow Sandal',
        color: 'black',
        thumb: {
          src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/in_and_out/products/SS19SL232-STELLA_LUNA-SANDAL-1_300x300.png`,
          srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/in_and_out/products/SS19SL232-STELLA_LUNA-SANDAL-1_500x500.png`
        }
      }
    ],
    content: [
      {
        className: `u-offset-1 u-col-8 u-offset-lg-1 u-col-lg-4`,
        src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/in_and_out/editorial/normal/08_STELLA_LUNA_190221_012.jpg`,
        srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/in_and_out/editorial/retina/08_STELLA_LUNA_190221_012.jpg`
      },
      {
        className: `u-offset-2 u-col-9 u-col-lg-4 u-offset-lg-2 u-mrg-t--300 u-mrg-reset-lg`,
        src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/in_and_out/editorial/normal/08_STELLA_LUNA_190221_093.jpg`,
        srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/in_and_out/editorial/retina/08_STELLA_LUNA_190221_093.jpg`
      },
      {
        className: `u-offset-0 u-col-9 u-col-lg-4 u-offset-lg-2`,
        src: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/in_and_out/editorial/normal/08_STELLA_LUNA_190221_152.jpg`,
        srcRetina: `https://cloudfront.stellaluna.co/editorial-page/fw19/ecom/in_and_out/editorial/retina/08_STELLA_LUNA_190221_152.jpg`
      }
    ]
  }
]

class GameEditorialPage extends Component {
  constructor(props) {
    super(props)
    this.parent = React.createRef()
    this.breadcrumbsData = [
      {
        path: `/edit`,
        title: `edit`,
        disable: true
      },
      {
        path: `/edit/${this.props.pageContext.slug}`,
        title: this.props.pageContext.title,
        disable: true
      }
    ]
  }
  componentDidMount() {
    window.addEventListener('resize', this.resize)
    this.resize()
    setTimeout(AppActions.finishLoadingPage, AppConstants.LOADING_PAGE_TIMEOUT)
  }
  componentDidUpdate() {
    this.resize()
    setTimeout(AppActions.finishLoadingPage, AppConstants.LOADING_PAGE_TIMEOUT)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.resize)
  }
  resize = () => {
    if (this.allVideos && this.allVideos.length > 0) {
      this.allVideos.forEach(item => { if (item.current) item.current.setSize() })
    }
  }
  onProductClick(e) {
    const url = e.currentTarget.getAttribute('data-url')
    navigate(url)
  }
  render() {
    this.allVideos = []
    const backgroundColor = this.props.pageContext.backgroundColor
    const items = data.map((item, index) => {
      const videoRef = React.createRef()
      this.allVideos.push(videoRef)
      const allMedia = item.content.map((media, i) => {
        return (
          <LazyLoad key={`story-media-img-${i}---${index}`} height={300} offset={100} once>
            <Img
              {...media}
              noRetinaOnMobile={true}
            />
          </LazyLoad>
        )
      })
      const allProducts = item.products.map((product, i) => {
        return (
          <div onClick={this.onProductClick} data-url={product.permalink} className={`${styles.itemWrapper} u-col-5 u-col-lg-3 u-btn`} key={`story-product--${index}---${i}`}>
            <Img
              {...product.thumb}
              noRetinaOnMobile={true}
            />
            <div className={`${styles.itemInfoWrapper}`}>
              <div className={`${styles.infoTitle}`}>{product.title} in {product.color}</div>
              <div className={`${styles.infoBtn}`}>Show more</div>
            </div>
          </div>
        )
      })
      return (
        <div key={`sticky-story-container--${index}`}>
          <div className={`u-text-center`}>
            <div className={`${styles.frontWrapper}`}>
              <div className={`${styles.frontInsideWrapper}`}>
                <div className={styles.title}>{item.title}</div>
                <div className={styles.lineWrapper}><div className={styles.line}></div></div>
                <p className={`u-col-lg-8 u-offset-lg-2`} dangerouslySetInnerHTML={{__html: item.instructions}} />
              </div>
            </div>
            <div className={styles.bgVideoWrapper}>
              <LazyLoad height={200} offset={200}>
                <Video
                  ref={videoRef}
                  full={true}
                  orientation={'PORTRAIT'}
                  soundControl={false}
                  src={item.background.src}
                  srcMobile={item.background.srcMobile}
                />
              </LazyLoad>
            </div>
          </div>
          <div className={styles.overflowWrapper}>
            <div className={`u-row`}>{ allMedia }</div>
            <div className={`u-height--120`} />
          </div>
          <LazyLoad height={300} offset={100} once>
            { allProducts.length > 0 &&  <div className={`${styles.productsWrapper} u-row`}>{ allProducts }</div>}
          </LazyLoad>
        </div>
      )
    })
    return (
      <Layout>
        <SeoHead
          title={`${this.props.pageContext.title}`}
          path={`/edit/${this.props.pageContext.slug}`}
        />
        { this.props.pageContext.enableBreadcrumbs && <Breadcrumbs items={this.breadcrumbsData}/>}
        <div ref={this.parent} style={{backgroundColor}}> { items } </div>
      </Layout>
    )
  }
}

export default GameEditorialPage
